
function TimeoutResponse({text}) {
    
   
    return(
        <div className="col-start-1 col-end-12 p-3 rounded-lg">
        <div className="flex flex-row items-center">
          <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl text-left">
            <div className="text-red-500">{text}</div>
          </div>
        </div>
      </div>
    );
}
export default TimeoutResponse;
