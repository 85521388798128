
function UserResponse({text}) {
    
   
    return(
        <div className="col-start-2 col-end-13 p-3 rounded-lg">
            <div className="flex items-center justify-start flex-row-reverse">
                <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white">
                    Y
                </div>
                <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl text-left">                      
                    <div>{text}</div>
                </div>
            </div>
        </div>
    );
}
export default UserResponse;
