
function ChatForm({inputsHandler,submitButton,inputField}) {
    
   
    return(
        <form id="user-input">
              <div
                className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-2 sm:px-4"
              >
              
                <div className="flex-grow sm:ml-4">
                  <div className="relative w-full">
                    <input
                      type="text"
                      name="human"
                      className="flex appearance-none w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-2 sm:pl-4 h-10"
                      placeholder="Chat with me here"
                      onChange={inputsHandler} 
                      value={inputField.human}
                    />
                  </div>
                </div>
                <div className="sm:ml-4 ml-2">

                  <button
                    className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-3 py-3 sm:px-4 sm:py-1 flex-shrink-0"
                    id="submitbtn"
                    type="submit"
                    onClick={submitButton}
                  >
                  
                    <span className="sm:block hidden">Send</span>
                    <span className="sm:ml-2 ml-1">
                      <svg
                        className="w-4 h-4 transform rotate-45 -mt-px"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                        ></path>
                      </svg>
                    </span>
                  </button>
                  
                </div>
              
              </div>
        </form>
    );
}
export default ChatForm;
