import logo from './logo.svg';
import './App.css';
import React, {useState,useEffect,useRef} from 'react';
import ChatForm from './components/ChatForm';
import UserResponse from './components/UserResponse';
import BotResponse from './components/BotResponse';
import ResponseLoader from './components/ResponseLoader';
import TimeoutResponse from './components/TimeoutResponse';
import { type } from '@testing-library/user-event/dist/type';

function App() {
  const [chatList, setChatList] = useState([]);
  const [oldChatList, setOldChatList] = useState(localStorage.getItem("chatlist") ? JSON.parse(localStorage.getItem("chatlist")) : []);

  const [isVisible, setIsVisible] = useState(true);
  const bottomRef = useRef(null);

  const [inputField , setInputField] = useState({
  human: '',
})
const inputsHandler = (e) =>{
    setInputField( {[e.target.name]: e.target.value} )
}
useEffect(() => {
  // Update the document title using the browser API
  if(inputField.human !== ''){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "human": inputField.human
    });
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    signal: Timeout(30).signal
    };
    var conv = localStorage.getItem("chatlist") ? JSON.parse(localStorage.getItem("chatlist")) : [];
    conv.push({'human':inputField.human});
    localStorage.setItem("chatlist", JSON.stringify(conv));

    fetch("https://eco-tape-377208.el.r.appspot.com/api/response", requestOptions)
    .then(res => res.json())
    .then(result => {
      console.log(result);
      if(result.value==''){
          //setChatList(chatList.concat(<TimeoutResponse text={'There is some issue with bot, please try again in a few minutes, sorry for the inconvenience'} key={chatList.length}/>));
          // Call API again incase value is empty
          setTimeout(function(){ 
            fetch("https://eco-tape-377208.el.r.appspot.com/api/response", requestOptions)
            .then(res => res.json())
            .then(result => {
              console.log(result);
              if(result.value==''){
                setChatList(chatList.concat(<TimeoutResponse text={'I’m having trouble with that question, can you try another one?'} key={chatList.length}/>));
              }else{
                setChatList(chatList.concat(<BotResponse text={result.value} key={chatList.length}/>));
              }
              conv.push({'bot':result.value});
              localStorage.setItem("chatlist", JSON.stringify(conv));
              removeLoader();
            })
            .catch(error => {
              if(error instanceof SyntaxError){
                setChatList(chatList.concat(<TimeoutResponse text={'This is taking longer than expected to respond to, please try again in a few minutes, sorry for the inconvenience'} key={chatList.length}/>));
              }else{
                setChatList(chatList.concat(<TimeoutResponse text={'This is taking longer than expected to respond to, please try again in a few minutes, sorry for the inconvenience'} key={chatList.length}/>));
              }
              removeLoader();
            });
          }, 5000);
      }else{
        setChatList(chatList.concat(<BotResponse text={result.value} key={chatList.length}/>));
        removeLoader();
      }
      conv.push({'bot':result.value});
      localStorage.setItem("chatlist", JSON.stringify(conv));
    })
    .catch(error => {
      if(error instanceof SyntaxError){
        setChatList(chatList.concat(<TimeoutResponse text={'This is taking longer than expected to respond to, please try again in a few minutes, sorry for the inconvenience'} key={chatList.length}/>));
      }else{
        setChatList(chatList.concat(<TimeoutResponse text={'This is taking longer than expected to respond to, please try again in a few minutes, sorry for the inconvenience'} key={chatList.length}/>));
      }
      removeLoader();
    });

   
    setInputField({human:''});
   
  }
 
  bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  
},[chatList]);
 const removeLoader = () =>{
  const elements = document.getElementsByClassName('chaticon');
  while(elements.length > 0){
      elements[0].parentNode.removeChild(elements[0]);
  }
 }

const submitButton = (e) =>{
    e.preventDefault();
    setChatList(chatList.concat([<UserResponse text={inputField.human} key={chatList.length}/>,<ResponseLoader key={chatList.length+1} isVisible={isVisible}/>]));
  }

 
  return (
    <div className="App mobHeight">
      <div className="flex h-full antialiased text-gray-800 mobHeight">
        <div className="flex flex-row h-full w-full overflow-x-hidden mobHeight">
          <div className="flex flex-col flex-auto h-full p-2 sm:p-6 mobHeight">
            <div
              className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-2 sm:p-4 justify-between mobHeight" 
            >
              <div className="flex flex-col h-full overflow-x-auto mb-4" id="scroller">
                <div className="flex flex-col h-full">
                  <div className="grid grid-cols-12 gap-y-2" id="chatbot">
                   {/*oldChatList.map((chat, index) => (  
                      chat.human ? <UserResponse text={chat.human} key={index}/> : <BotResponse text={chat.bot} key={index}/>
                      
                   ))*/}    
                    {chatList}
                    <div ref={bottomRef} />
                  </div>
                </div>
              </div>
              <ChatForm inputsHandler={inputsHandler} submitButton={submitButton}  inputField={inputField}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;



export const Timeout = (time) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);
  return controller;
};