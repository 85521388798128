
function BotResponse({text}) {
    
   
    return(
        <div className="col-start-1 col-end-12 p-3 rounded-lg">
        <div className="flex flex-row items-center">
          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white">
            B
          </div>
          <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl text-left">
            <div>{text}</div>
          </div>
        </div>
      </div>
    );
}
export default BotResponse;
