import gif from '../images/827.gif';
function ResponseLoader(isVisible) {
    
   
    return(
        <div className="relative ml-3 mb-6 text-sm bg-white py-2 px-4 shadow rounded-xl chaticon" style={{width:'59px'}}>
            <img className="chaticon" src={gif} alt="gif"/>
        </div>
    );
}
export default ResponseLoader;
